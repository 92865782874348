.inst-comment {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  width: fit-content;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
    text-decoration: underline;
  }
}
