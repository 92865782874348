@import "../../styles/theme";

.ssc_notfound_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70%;

  .ssc_notfound_header {
    width: 60%;
    text-align: center;
  }
}
