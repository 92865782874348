@import "../../styles/theme";

.ssc_profile_container {
  // background-color: #fff;

  .profile-title {
    color: #1976d2;
  }

  .student-profile-detail {
    background-color: #fff;
    padding: 20px;

    .student-profile-image-container {
      position: relative;

      .student-profile-edit-btn {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .image-box {
      width: fit-content;
      position: relative;
      margin: 0;
      .student-profile-image {
        cursor: zoom-in;
        width: 210px;
        height: 210px;
        object-fit: cover;
        border-radius: 5px;

        &:hover {
          opacity: 0.6;
        }
      }

      .float-navigator-btn {
        position: absolute;
        right: 0;
        bottom: 0;
        opacity: 0;
        transition: all 0.15s ease;
        background: #e5e6eb;
        color: #000;
        box-sizing: border-box;
        border: 3px solid #fff;
        padding: 10px;

        // outline-offset: -3px;

        &:hover {
          background: #c7cbcf;
        }
      }

      &:hover {
        .float-navigator-btn {
          transform: translate(25%, 10%);
          opacity: 1;
        }
      }
    }

    .student-name {
      font-weight: bold;
      font-family: KhmerOSContent, sans-serif;
    }
  }
}

.ssc_container {
  .row-class {
    background-color: #ddd;
  }
}
