@import "../../../styles/theme";

.student-profile-payment {
  background-color: #fff;

  .student-profile-payment-title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
  }
}
