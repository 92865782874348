.expire-message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #e74d3c7b;
  padding: 2px;
  z-index: 1100;
  text-align: center;
  color: #fff;
  font-size: 14px;
}
