@import "../../styles/theme";

.MuiAutocomplete-root {
  .MuiAutocomplete-listbox {
    .MuiAutocomplete-option {
      font-family: KhmerOSContent, sans-serif !important;
    }
  }

  .student-autocomplete-option {
    font-family: KhmerOSContent, sans-serif !important;
  }
}
