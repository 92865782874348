table {
  tr.total {
    td {
      &:nth-child(2),
      &:nth-child(3) {
        border-top: 1px solid #eee;
        font-weight: bold;
      }

      &:nth-child(4) {
        border-top: 1px solid #eee;
        font-weight: bold;
        text-align: right;
      }
    }

    &:first-child {
      td {
        &:nth-child(2),
        &:nth-child(3) {
          border-top: none !important;
          font-weight: bold;
        }

        &:nth-child(4) {
          border-top: none !important;
          font-weight: bold;
          text-align: right;
        }
      }
    }

    &:last-child {
      font-size: 15px;
      background-color: #eee;
    }
  }
}
