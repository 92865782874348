@import "../../styles/theme";

.ssc_st_card_container {
  background-color: #6c757d;
  height: 100vh;
}

.ssc_st_card_container .loading-container {
  width: 100%;
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
  background-color: #6c757d;
}

.print-st-body {
  width: 366px;
  margin: 0 auto;
  position: relative;
}

.st-text-container{
  width: 100%;
  height: 200px;
  z-index: 20;
  position: absolute;
  left : 50%;
  bottom: 19px;
  font-family: romnea-italic !important;
  font-size: 18px;
  color: #000 !important;
  // letter-spacing: -px;
  line-height: 1.325;
}

.print-st-body img {
  margin-bottom: -13px;
  // border: 1px solid #ccc;
  z-index: 10;
  width: 366px !important;
}

.image-student {
  width: 100%;
  position: absolute;
  top: 99px;
  // left: 1px;
  display: flex;
  justify-content: center;
  z-index: 5;
}

.st-main-name {
  position: absolute;
  left: 0;
  bottom: 226px;
  width: 100%;
  // background-color: teal;
  z-index: 25;
  font-family: berlin !important;
  text-align: center;
  font-size: 28px;
  // font-weight: bold;
  // letter-spacing: 2.5px;
  color: #000;
  // padding-left: 5px;
}

.image-student .worker_image {
  min-width: 200px !important;
  // height: 185px;
  border: none;
}

.image-student .image-worker {
  // opacity: 0.5;
  width: 170px;
  height: 185px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -3px;
  // border: 2.7px solid #e6e7e9;
}
