$primary-color: blue;
$secondary-color: grey;
$font-color: black;
$spacing20: 20px;
$spacing15: 15px;
$badgeColor: red;
$success-color: blue;
$error-color: blue;

@font-face {
  font-family: KhmerOSContent;
  src: url("../assets/fonts/KhmerOSContent.ttf");
}

@font-face {
  font-family: khmer-os-sr;
  font-display: swap;
  src: url("../assets/fonts/KHMEROSSIEMREAP.TTF");
}

@font-face {
  font-family: khmer-os-mo;
  font-display: swap;
  src: url("../assets/fonts/KHMEROSMUOLLIGHT.TTF");
}

@font-face {
  font-family: berlin;
  font-display: swap;
  src: url("../assets/fonts/BERLIN.TTF");
}

@font-face {
  font-family: bahnschrift;
  font-display: swap;
  src: url("../assets/fonts/bahnschrift.ttf");
}

@font-face {
  font-family: franklin-gothic;
  font-display: swap;
  src: url("../assets/fonts/franklin-gothic.TTF");
}

@font-face {
  font-family: romnea-italic;
  font-display: swap;
  src: url("../assets/fonts/Romnea-Italic.ttf");
}

@mixin mobile {
  @media only screen and (max-width: 768px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: 992px) {
    @content;
  }
}

@mixin deviceHeight799 {
  @media screen and (max-height: 799px) {
    @content;
  }
}

@mixin printMedia {
  @media print {
    @content;
  }
}

@mixin customScroll() {
  /* Track */
  // ::-webkit-scrollbar-track {
  //   @include tablet {
  //     padding: 2px;
  //   }
  // }

  ::-webkit-scrollbar {
    @include tablet {
      background-color: none !important;
      width: 14px;
      border-radius: 9999px;
      height: 14px;
    }
  }

  ::-webkit-scrollbar-thumb {
    @include tablet {
      background-color: #aaa;
      border-radius: 9999px;
      background-clip: padding-box;
      border: 4px solid rgb(0 0 0 / 0%);
    }
  }
}

.ssc_text_field {
  height: 40px !important;
}
