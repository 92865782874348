@import "../../styles/theme";

.ssc_custom_table {
  overflow-x: auto;

  table {
    width: 100%;
    border: 1px solid #eee;
    border-collapse: collapse;
    font-size: 15px;
    position: relative;

    thead {
      background-color: #fafafa;

      th {
        padding: 8px;
        border-bottom: 1px solid #eee;
        position: relative;

        &:first-child {
          &::before {
            content: "";
            position: absolute;
            width: 0;
          }
        }

        &::before {
          content: "";
          position: absolute;
          background-color: #eee;
          width: 1.5px;
          height: 70%;
          left: 0;
          top: 4px;
        }

        &.column-sticky {
          position: sticky;
          right: -1px;
          background-color: #fafafa;
        }
      }
    }

    tbody {
      background: #fff;

      tr {
        td {
          padding: 3px 8px;
          border-bottom: 1px solid #eee;

          &.column-sticky {
            background-color: #fff;
            position: sticky;
            right: -1px;

            // display: block;
            box-shadow: inset 1px 0 0 #ddd;
          }
        }

        &:hover {
          background-color: #eee;

          // cursor: pointer;

          td {
            &.column-sticky {
              background-color: #eee;
            }
          }
        }

        &.row-danger {
          // cursor: pointer;
          td {
            background-color: #ffecf8;

            &.column-sticky {
              background-color: #ffecf8;
            }
          }

          &:hover {
            td {
              background-color: #eee;
            }
          }
        }

        &.row-disabled {
          td {
            background-color: #f8f7f7;
            color: #aaa;
          }

          &:hover {
            cursor: not-allowed;

            td {
              background-color: #eee;
            }
          }
        }

        &.row-gray {
          td {
            background-color: #f8f7f7;
            color: #aaa;
          }

          &:hover {
            td {
              background-color: #eee;
            }
          }
        }
      }

      &:last-child {
        border-bottom: none;
      }

      .ssc_row_print_outdoor {
        background-color: #e6f7ff;
      }
    }
  }
}
