@import "../../styles/theme";

.ssc-feedback-detail-container {
  // background-color: #fff;

  .profile-title {
    color: #1976d2;
  }

  .feedback-detail {
    background-color: #fff;
    padding: 20px;

    .feedback-image-container {
      position: relative;

      .feedback-edit-btn {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  .feedback-detail-title {
    padding: 5px 10px;
    background-color: #858796;
    color: white;
    font-size: 18px;
  }
}
