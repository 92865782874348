.drop-image-zone {
  width: 100%;
  background-color: #c8dadf;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 2px dashed #fff;
  outline-offset: -10px;
  border-radius: 10px;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.5s ease;

  &.active {
    background-color: white;
    outline-color: #c8dadf;
  }
}
