@import "../../styles/theme";

.ssc_profile_container {
  // background-color: #fff;

  .profile-title {
    color: #1976d2;
  }

  .option-btn {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .student-name {
    font-weight: bold;
    font-family: KhmerOSContent, sans-serif;
  }
}

.ssc_container {
  .row-class {
    background-color: #ddd;
  }
}
