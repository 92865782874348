@import "../../styles/theme";

.ssc_stcard_container {
  background-color: #6c757d;
  height: fit-content;

  // -webkit-user-select: none; /* Safari */
  // -ms-user-select: none; /* IE 10 and IE 11 */
  // user-select: none; /* Standard syntax */
  // pointer-events: none;
  // height: 100vh;
}

.ssc_stcard_container .loading-container {
  width: 100%;
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
  background-color: #6c757d;
}

.print-body {
  /* background-color: aquamarine; */

  /* width: 640px;
  height: 1918px; */
  width: 366px;
  margin: 0 auto;
  position: relative;
}

.print-body img {
  margin-bottom: -13px;
  border: 1px solid #ccc;
}

.print-body .text-container {
  width: 100%;
  position: absolute;
  top: 51.5%;
  left: 0;

  // background-color: red;
  text-align: center;
  font-family: bahnschrift, sans-serif !important; // bahnschrift
  letter-spacing: -1px;
}

.text-container .worker_name {
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
  color: #fff;
  text-transform: uppercase;
  // font-weight: bold;
  // background-color: aqua;
  padding-left: 23px;
  padding-right: 23px;
  height: 59px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
}

.text-container .worker_position {
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
  color: #fff;
  // margin-top: 1px;
  font-size: 19px;
  font-family: bahnschrift, sans-serif !important; // bahnschrift
}

.card-expire-date {
  font-family: bahnschrift, sans-serif !important; // bahnschrift
  padding: 0;
  color: #036;
  position: absolute;
  bottom: 38px;
  left: 0;
  font-size: 13.7px;
  font-weight: 700;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  // border-bottom: 1px solid #ccc;
  text-decoration: underline;
  text-underline-offset: 2.8px;
  letter-spacing: -0.4px;
}

.image-container {
  width: 100%;
  position: absolute;
  top: 82.2px;
  left: 1px;
  display: flex;
  justify-content: center;
}

.image-container .worker_image {
  // width: 174.5px;
  height: 229.7px;
  object-fit: cover;
  border: none;
  // background-size: cover;
  // background-position: center;
}

.image-container .image-worker {
  // opacity: 0.5;
  width: 153.3px;
  height: 203.5px;
  overflow: hidden;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  display: flex;
  justify-content: center;
  margin-left: -0.8px;
  border: 2.7px solid #e6e7e9;
}
