@import "../../../styles/theme";

.student-profile-schedule {
  background-color: #fff;

  .student-profile-schedule-title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  .student-profile-schedule-table {
    .row-past {
      td {
        background-color: #f8f7f7;
        color: #aaa;
      }

      &:hover {
        cursor: not-allowed;

        td {
          background-color: #eee;
        }
      }
    }
  }
}
