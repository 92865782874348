@import "../../styles/theme";
@import "https://fonts.googleapis.com/css?family=Roboto&display=swap";

// $sidebar-bg-color: #0c1e35;rgb(180, 217, 255)
$sidebar-bg-color: rgb(180 217 255);
$submenu-bg-color: rgb(255 255 255 / 5%);
$submenu-bg-color-collapsed: rgba(#0c1e35, 0.95);

// $sidebar-color: #b3b8d4;#0c1e35;
$sidebar-color: #0c1e35;

// $highlight-color: #dee2ec;
$highlight-color: #0c1e35;
$icon-bg-color: rgb(255 255 255 / 5%);
$icon-size: 35px;

@import "~react-pro-sidebar/dist/scss/styles";

.custome-sidebar {
  height: auto !important;
  min-width: 200px;
  width: 200px;
  border-right: 1px solid #f0f0f0;
  direction: rtl;

  @include mobile {
    height: 100% !important;
    width: 270px;
    min-width: 270px;
  }

  .pro-menu-item {
    direction: ltr;

    .pro-inner-item {
      // padding: 4px 0 4px 20px !important;
      padding: 4px 0 4px 10px !important;
    }

    &.active_tab {
      // border-right: 5px solid #b3b8d4;
      .pro-inner-item {
        background-color: #e6f7ff;
        font-size: 16px;
        transition: border-right 0.3s ease-in; // cubic-bezier(0.215, 0.61, 0.355, 1)
        border-right: 3px solid #1976d2;
        z-index: 1500;
        font-weight: bold;

        .pro-icon-wrapper {
          background-color: #0c1e35 !important;

          // transform: rotate(-370deg);
          transition: all 0.3s;
          color: #fff;
        }
      }
    }

    &.pro-sub-menu {
      &.active_tab {
        .pro-inner-item {
          border-right: none !important;
        }
      }

      .pro-inner-list-item {
        background-color: #cbd3d6 !important;
        padding-left: 0 !important;

        ul {
          padding-top: 5px !important;
          padding-bottom: 5px !important;
        }

        .pro-menu-item {
          .pro-inner-item {
            font-weight: normal;
            padding-left: 30px !important;
            background-color: #cbd3d6 !important;
            font-size: 15px;
          }

          &.active_tab {
            .pro-inner-item {
              // background-color: #cbd3d6 !important;
              background-color: #e6f7ff !important;
              border-right: 3px solid #1976d2 !important;
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  .new-student-badge {
    .MuiBadge-badge {
      background-color: red !important;
    }
  }

  .sidebar_footer {
    .logout_footer {
      &:hover {
        .pro-inner-item {
          // padding: 8px 25px 8px 10px !important;
          padding: 4px 0 4px 10px !important;
          margin: 0 10px;
          background-color: #715459;
          font-size: 16px;
          border-radius: 10px;
          transition: all 0.7s;
          color: #fff;

          .pro-icon-wrapper {
            background-color: #0c1e35 !important;

            // transform: rotate(-360deg);
            transition: all 0.7s;
          }
        }
      }
    }
  }
}
