@import "../../styles/theme";

.ssc_certificate_container {
  background-color: #6c757d;
  height: 100vh;
  user-select: none; /* Safari */
  pointer-events: none;
}

.ssc_certificate_container .loading-container {
  width: 100%;
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
  background-color: #6c757d;
}

.print-certificate {
  width: 1683px;
  position: relative;
  background-color: #fff;
}

.print-certificate .student-name {
  position: absolute;
  top: 29%;
  left: 0;
  width: 82.6%;
  text-transform: uppercase;
  text-align: center;
}

.print-certificate .student-name h2 {
  margin: 0;
  font-size: 80px;
  font-family: berlin, sans-serif;
  color: #3e608e;
}

// completed lesson
.print-certificate .student-lesson {
  position: absolute;
  top: 41.9%;

  // top: 45%;
  left: 0;
  width: 100%;

  // padding-left: 95px;
  padding-left: 115px;
}

.student-lesson .lesson-list {
  line-height: 1.27;

  // background-color: blue;
  width: 480px;
  font-family: bahnschrift, sans-serif !important; // bahnschrift
  color: #036;

  // color: #5fafff;
  font-size: 41px;
  font-weight: 350;
  list-style: none;
  display: flex;
  gap: 15px;
  font-stretch: semi-condensed;
  letter-spacing: -0.4px;
}

.lesson-list::first-letter {
  text-transform: capitalize;
}

.ssc-student-img-box {
  position: absolute;
  top: 158px;
  right: 65px;
}

.ssc-student-img-box .ssc-frame-image {
  width: 309px;
  z-index: 20;
  position: relative;
}

.ssc-student-img-box .ssc-image-container {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  width: 309px;
  height: 353px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 200px;
}

.ssc-image-container .ssc-student-image {
  width: 230px;
  height: 235px;
  object-fit: cover;
}

.print-certificate .ssc-badge {
  position: absolute;
  top: 52%;
  left: 0;
  width: 82.6%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ssc-badge .ssc-bage-image {
  width: 230px;
}

.ssc-badge .ssc-badge-count {
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
  position: absolute;
  font-size: 82px;
  font-family: bahnschrift, sans-serif !important; // bahnschrift
  top: 75px;
  color: #fff !important;
}

.student-no-date {
  line-height: 1.27;
  position: absolute;
  bottom: 22%;
  left: 0;
  width: 100%;
  padding-left: 115px;
  color: #036;
  font-size: 34px;
  font-weight: 600;
  font-family: bahnschrift, sans-serif !important; // bahnschrift
  font-stretch: semi-condensed;
  letter-spacing: -0.4px;
}

.student-no-date .student-no {
  color: red;
}
