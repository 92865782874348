@import "./theme";

body {
  margin: 0;

  // height: 100vh;
  // height: 100%;
  color: #353535;

  // font-family: Roboto, sans-serif;
  font-family: KhmerOSContent, sans-serif;
}

.app {
  height: 100vh;
  display: flex;
  position: relative;
  background-color: #ddd;

  @include customScroll;

  .ssc_container {
    // height: 74vh;
    overflow-y: none;
  }

  // .sidebar-btn-wrapper {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   .sidebar-btn {
  //     transition: width 0.3s;
  //     width: 150px;
  //     padding: 1px 15px;
  //     border-radius: 40px;
  //     background: rgba(255, 255, 255, 0.05);
  //     color: #adadad;
  //     text-decoration: none;
  //     margin: 0 auto;
  //     height: 35px;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     text-overflow: ellipsis;
  //     overflow: hidden;
  //     span {
  //       margin-left: 5px;
  //       font-size: 13px;
  //     }
  //     &:hover {
  //       color: $highlight-color;
  //     }
  //   }
  // }
}
