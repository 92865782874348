@import "../../styles/theme";

@keyframes ripple {
  0% {
    transform: scale(0.8);
    opacity: 1;
  }

  100% {
    transform: scale(2.4);
    opacity: 0;
  }
}

.ssc_header {
  width: 100%;
  position: relative;
  overflow: hidden;

  .ssc-santa {
    position: absolute;
    left: 0;
    bottom: -100px;
    transition: all 0.5s;
    background: none;
    border: none;
    outline: none;
    padding: 0px;

    :active {
      transform: scale(0.95);
    }

    @include mobile {
      left: 60px;
    }

    &.active-animate {
      bottom: -5px;
    }
  }

  /* count down */
  .react-countdown li:after {
    top: -2px !important;
    font-size: 20px !important;
    right: -11px;
  }
  .react-countdown .digit {
    font-size: 20px !important;
  }
  .react-countdown .text {
    font-size: 8px !important;
  }

  .ssc_header_content {
    padding: 0 20px;

    .btn-toggle {
      cursor: pointer;
      width: 50px !important;
      height: 50px;
      background: #353535;
      color: #fff;
      text-align: center;
      opacity: 0;
      border-radius: 10px;
      position: fixed;
      z-index: 1010;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      transition: all 0.3s ease;
      left: 15px;
      top: 10px;

      &.active {
        left: 80%;
        border-radius: 50%;
      }

      @include mobile {
        opacity: 1;
      }
    }

    .avatar-container {
      position: relative;

      .badge-active {
        position: absolute;
        right: 10px;
        bottom: 5px;

        .MuiBadge-badge {
          background-color: #44b700 !important;
          color: #44b700 !important;
          box-shadow: 0 0 0 2px #fff;

          &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            animation: ripple 1.2s infinite ease-in-out;
            border: 1px solid #44b700;
            content: "";
          }
        }
      }
    }
  }
}
