@import "../../styles/theme";
@include customScroll;

.custom-drawer {
  .MuiPaper-root {
    width: 600px;
    font-family: KhmerOSContent, sans-serif !important;

    .MuiInputBase-input {
      font-family: KhmerOSContent, sans-serif !important;
    }

    .drawer-header {
      border-bottom: 1px solid rgb(143 143 143 / 20%);
    }

    .drawer-container {
      overflow-y: auto;
    }

    @include mobile {
      width: 100% !important;
    }
  }

  .MuiPopperUnstyled-root {
    .MuiPaper-root {
      width: 100%;
    }
  }
}
